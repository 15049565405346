import {Options, Property, PropertyTypes} from '@Interfaces/index';
import {DatePipe} from '@angular/common';
import {CurrencyPipe} from '@Components/util/currency.pipe';
import * as moment from 'moment';
import * as SparkMD5 from 'spark-md5';
import {ParamDate} from '@Components/date-range-selection/date-range-selection.component';

export class AdfiUtil {

    public static URL_CLOUD = 'https://storage.googleapis.com/';
    private static format = 'mediumDate';
    private static formatFullDate = 'medium';
    private static locale = 'es-CO';
    private static REF = 'a74c90c7a91a7e01013f2071951fa3ae73a754f2b9e35f6ce11dda';

    public static getBasicFieldsGraphql(columns) {
        const basicFields = columns.filter((prop: Property) =>
            prop.type !== PropertyTypes.MANY_TO_ONE && prop.type !== PropertyTypes.ONE_TO_MANY
            && prop.type !== PropertyTypes.MANY_TO_MANY && prop.type !== PropertyTypes.ONE_TO_ONE).map(
            (prop: Property) => prop.fieldName);
        return basicFields.join('\n');
    }

    public static getFieldManyToOne(columns) {
        const fields = columns.filter((prop: Property) => prop.type === PropertyTypes.MANY_TO_ONE);
        return fields.map((value: Property) => (`${value.fieldName}{id \n${value.query}\n}\n`));
    }

    public static getFieldOneToOne(columns) {
        const fields = columns.filter((prop: Property) => prop.type === PropertyTypes.ONE_TO_ONE);
        return fields.map((value: Property) => (`${value.fieldName}{id \n${value.query}\n}\n`));
    }

    public static getFieldManyToMany(columns) {
        const fields = columns.filter((prop: Property) => prop.type === PropertyTypes.MANY_TO_MANY);
        return fields.map((value: Property) => (`${value.fieldName}{\nedges{\nnode{id \n ${value.query}\n}\n}\n}\n`));
    }

    public static getFieldOneToMany(columns) {
        const fields = columns.filter((prop: Property) => prop.type === PropertyTypes.ONE_TO_MANY);
        return fields.map((value: Property) => (`${value.fieldName}{\nedges{\nnode{id _id \n ${value.query}\n}\n}\n}\n`));
    }

    public static getDataOneToMany(row: any, properties: Property, returnArray = false) {
        let str = returnArray ? [] : '';
        if (properties.display && row[properties.fieldName] && row[properties.fieldName].edges && row[properties.fieldName].edges.length) {
            const edges = row[properties.fieldName].edges;
            edges.forEach(e => {
                if (typeof str !== 'string') {
                    str.push(e.node);
                } else {
                    str += this.getDataColumn(e.node, properties);
                }
            });
        }
        return str;
    }

    public static getDataManyToMany(row: any, properties: Property) {
        return this.getDataOneToMany(row, properties, true);
    }

    public static getDataManyToOne(row: any, properties: Property) {
        let str = '';
        const data = row.__typename === properties.column.entity ? row : row[properties.fieldName] ? row[properties.fieldName] : undefined;
        if (data) {
            str += this.getDataColumn(data, properties);
        }
        return str;
    }

    public static downloadMediaObject(mediaObject, property: Property | { fieldName, options }, periodUser) {
        // validation from initial budget
        let period;
        if(typeof mediaObject === 'object' && mediaObject['vigencia'] !== undefined && !(property.options && property.options[Options.BUCKET_NO_VIGENCIA])){
            period = mediaObject['vigencia'];
        } else if(typeof mediaObject === 'object' && mediaObject['period'] !== undefined && !(property.options && property.options[Options.BUCKET_NO_VIGENCIA])) {
            period = mediaObject['period'];
        } else if(typeof mediaObject === 'object' && property.options && property.options[Options.BUCKET_CUSTOM_VIGENCIA]) {
            period = mediaObject[property.options[Options.BUCKET_CUSTOM_VIGENCIA]];
        } else if(!(property.options && property.options[Options.BUCKET_NO_VIGENCIA])){
            period = periodUser;
        }
        window.open(`${AdfiUtil.URL_CLOUD}${property.options[Options.BUCKET]}${period?('_'+period):''}/${typeof (mediaObject) === 'string' ? mediaObject : mediaObject[property.fieldName]}`, '_blank');
    }

    public static capitalizeFirstLetter(text: string) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public static capitalizeLFirstLetter(text: string) {
        return text.charAt(0).toLowerCase() + text.slice(1);
    }

    public static getColumnValue(row: any, property: Property) {
        if (row && (property && property.options && !property.options[Options.MULTIPLE_FILE])) {
            let valueCol = row[property.fieldName];
            if (property.type === 'Id') {
                valueCol = row[`_${property.fieldName}`] ? row[`_${property.fieldName}`] : row[property.fieldName];
            }
            valueCol = this.transformColumnValue(valueCol, property.formType, row, property);
            return valueCol;
        } else {
            return '';
        }
    }

    public static getDataColumn(row: any, property: Property) {
        if (!property.display) {
            return '';
        }
        return this.evaluateColumnsToDisplay(row, property.display, property.customFormatDisplay, property.separators, property, 0);
    }

    public static transformColumnValue(valueCol: any, format: string, obj: any, property: Property) {
        switch (format) {
            case PropertyTypes.DATE:
                return new DatePipe(this.locale).transform(valueCol, this.format, null, this.locale);
            case PropertyTypes.DATE_TIME:
                return new DatePipe(this.locale).transform(valueCol, this.formatFullDate, null, this.locale);
            case PropertyTypes.DROPDOWN:
                const value = property.options[PropertyTypes.DROPDOWN].find(val => val[Options.KEY] === valueCol);
                return value ? value[Options.VALUE_PROPERTY] : valueCol;
            case PropertyTypes.CURRENCY:
                return new CurrencyPipe().transform(valueCol);
            case PropertyTypes.MANY_TO_ONE:
            case PropertyTypes.ONE_TO_ONE:
                return AdfiUtil.getDataManyToOne(obj, property);
            case PropertyTypes.ONE_TO_MANY:
                return AdfiUtil.getDataOneToMany(obj, property);
            case PropertyTypes.MANY_TO_MANY:
                return AdfiUtil.getDataManyToMany(obj, property);
            case PropertyTypes.FILE:
                if (obj[property.fieldName] && property.display) {
                    return obj[property.display[0]];
                }
                break;
            default:
                return valueCol;
        }
    }

    public static formatText(value: string, format: string) {
        switch (format) {
            case PropertyTypes.DATE:
                return new DatePipe(this.locale).transform(value, this.format, null, this.locale);
            case PropertyTypes.DATE_TIME:
                return new DatePipe(this.locale).transform(value, this.formatFullDate, null, this.locale);
            case PropertyTypes.CURRENCY:
                return new CurrencyPipe().transform(value);
            default:
                return value;
        }
    }

    public static evaluateColumnsToDisplay(row, columns, formats, separators, property: Property, consecutive: number) {
        if (row === null || row === undefined) {
            return '';
        }
        let arr;
        let isObj = false;
        if (Array.isArray(columns)) {
            arr = columns;
        } else {
            isObj = true;
            arr = Object.keys(columns);
        }
        let str = '';
        if (row.edges && Array.isArray(row.edges)) {
            str = `(${row.edges.length}) [`;
            row.edges.forEach((child, ic) => {
                arr.forEach((attr, i) => {
                    const column = isObj ? columns[attr] : attr;
                    if (typeof column === 'string') {
                        str += this.extractSubfieldsWithFormat(child.node, column, formats, i, separators, property);
                    } else {
                        consecutive++;
                        const customFormats = property.customFormatDisplay && property.customFormatDisplay[consecutive] ?
                            property.customFormatDisplay[consecutive].split('_') : undefined;
                        const customSep = property.separators && property.separators[consecutive] ?
                            property.separators[consecutive].split('_') : undefined;
                        str += this.evaluateColumnsToDisplay(child.node[attr], column, customFormats, customSep, property, consecutive);
                    }
                });
                str += ic + 1 < row.edges.length ? ', ' : '';
            });
            str += ']';
            return str;
        } else {
            arr.forEach((display, index) => {
                const column = isObj ? columns[display] : display;
                if (typeof column === 'string') {
                    str += `${this.extractSubfieldsWithFormat(row, column, formats, index, separators, property)}`;
                } else {
                    consecutive++;
                    const customFormats = property.customFormatDisplay && property.customFormatDisplay[consecutive] ?
                        property.customFormatDisplay[consecutive].split('_') : undefined;
                    const customSep = property.separators && property.separators[consecutive] ?
                        property.separators[consecutive].split('_') : undefined;
                    str += this.evaluateColumnsToDisplay(row[display], column, customFormats, customSep, property, consecutive);
                }
            });
        }
        return str;
    }

    public static extractSubfieldsWithFormat(col, field, formats, index: number, separators, properties: Property) {
        const subfields = field.split('.');
        let data = col;
        for (const fie of subfields) {
            data = data ? data[fie] : '';
        }
        data = formats ? AdfiUtil.transformColumnValue(data, formats[index], col, properties) : data;
        if (data !== null && data !== undefined) {
            return separators && separators[index] ? data + separators[index] : data + ' ';
        }
        return '';
    }

    public static extractSubfields(col, field) {
        const subfields = field.split('.');
        let data = col;
        if (subfields.length >= 1 && subfields[0] !== '' ) {
            for (const fie of subfields) {
                data = data ? data[fie] : '';
            }
        }
        return data;
    }

    public static addToTree(tree, arr) {
        for (let i = 0, length = arr.length; i < length; i++) {
            tree = tree[arr[i]] = tree[arr[i]] || {};
        }
    }

    public static treeToStr(tree: any, field?: string) {
        let str = field ? field : '';
        const arr = Object.entries(tree);
        arr.forEach(([key, value], index: number) => {
            str += field && index === 0 ? '{' : '';
            str += this.treeToStr(value, key);
            str += field && index === arr.length - 1 ? '}' : '';
        });
        return str;
    }

    public static getDirFile(prop: Property, row: any) {
        const dirs = prop.options[Options.FOLDERS] ? prop.options[Options.FOLDERS] : [];
        let strDir = '';
        dirs.forEach((dir: string) => {
            const subFields = dir.split('.');
            let data = row;
            subFields.forEach(fieldName => {
                data = data[fieldName];
            });
            strDir += `${data}/`;
        });
        return strDir;
    }

    public static encrypt(message: string) {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ('0' + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(this.REF).reduce((a, b) => a ^ b, code);
        return message.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }

    private static decrypt(encoded: string) {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(this.REF).reduce((a, b) => a ^ b, code);
        return encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    public static saveInStorage(key: string, value: string) {
        if (key && value) {
           localStorage.setItem(this.encrypt(key), this.encrypt(value));
        }
    }

    public static getFromStorage(key: string): string {
        const value = localStorage.getItem(this.encrypt(key));
        return value ? this.decrypt(value) : undefined;
    }

    public static removeFromStorage(key: string) {
        if (key) {
            localStorage.removeItem(this.encrypt(key));
        }
    }

    public static getUniversalDateString(val, col?) {
        let newVal = val;
        if (newVal instanceof moment) {
            if (col && col.options[Options.ADD_END_OF_DAY]) {
                val.set({hour: 23, minute: 59, second: 59});
            }
            newVal = val.toISOString(true);
        } else if (newVal instanceof Date) {
            if (col && col.options[Options.ADD_END_OF_DAY]) {
                newVal.setHours(23);
                newVal.setMinutes(59);
                newVal.setSeconds(59);
            }
            newVal = newVal.toUTCString();
        } else if (val !== null && val !== "") {
            newVal = new Date(val);
            if (col && col.options[Options.ADD_END_OF_DAY]) {
                newVal.setHours(23);
                newVal.setMinutes(59);
                newVal.setSeconds(59);
            }
            newVal = newVal.toUTCString();
        } else {
            newVal = null;
        }
        return newVal;
    }

    static computeChecksumMd5Hash(file: any) {
        return new Promise((resolve, reject) => {
            const chunkSize = 2097152; // Read in chunks of 2MB
            const spark = new SparkMD5.ArrayBuffer();
            const fileReader = new FileReader();

            let cursor = 0; // current cursor in file

            fileReader.onerror = () => {
                reject('MD5 computation failed - error reading the file');
            };

            // read chunk starting at `cursor` into memory
            function processChunk(chunkStart: number): void {
                const chunkEnd = Math.min(file.size, chunkStart + chunkSize);
                fileReader.readAsArrayBuffer(file.slice(chunkStart, chunkEnd));
            }

            // when it's available in memory, process it
            // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
            // of `any` for `e` variable, otherwise stick with `any`
            // See https://github.com/Microsoft/TypeScript/issues/25510
            fileReader.onload = (e: any) => {
                spark.append(e.target.result); // Accumulate chunk to md5 computation
                cursor += chunkSize; // Move past this chunk

                if (cursor < file.size) {
                    // Enqueue next chunk to be accumulated
                    processChunk(cursor);
                } else {
                    // Computation ended, last chunk has been processed. Return as Promise value.
                    // This returns the base64 encoded md5 hash, which is what
                    // Rails ActiveStorage or cloud services expect
                    resolve(spark.end());

                    // If you prefer the hexdigest form (looking like
                    // '7cf530335b8547945f1a48880bc421b2'), replace the above line with:
                    // resolve(spark.end());
                }
            };

            processChunk(0);
        });
    }

    public static getNameFile(prefix = 'Reporte', selectedDates: ParamDate, deDescripcion?) {
        const start = AdfiUtil.formatDate(selectedDates.startDate);
        const end = AdfiUtil.formatDate(selectedDates.endDate);
        if (!deDescripcion) {
            return `${prefix}-(${start}-${end})`.replace(/\s+/, '_');
        } else {
            return `${prefix}${deDescripcion}-(${start}-${end})`
                .replace(/\s+/, '_');
        }
    }

    public static  formatDate(date: Date) {
        const month = date.getMonth() + 1;
        return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '_' +
            (month < 10 ? '0' + month : month) + '_' + date.getFullYear();
    }
}
