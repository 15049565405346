import {Component, OnInit, ViewChild} from '@angular/core';
import {NavRouteService} from 'src/app/services/nav-routing-service';
import {AdfiService} from '@services/adfi-service';
import {NavRoute} from 'src/app/utils/menu';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import {ENUM_ACTIONS, PeriodState, Permission} from '@Interfaces/index';
import {SelectItemComponent} from '@Components/ui/select-item/select-item.component';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {AdfiUtil} from '@Components/util/adfi-util';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

    /**
     * View child of nav component
     */
    @ViewChild('templateId', {static: true}) templateId;

    /**
     * Menu  of nav component
     */
    public menus: NavRoute[];
    chCencos = false;
    chVigecia = false;
    chRole = false;
    validRolesForStatistics = [283, 440, 380, 320, 448,142,162];
    validRolesForAlternativePath = [142, 162];
    private module = 'general';

    constructor(
        private loading: LoadingService,
        public navRouteService: NavRouteService,
        public adfiService: AdfiService,
        private matDialog: MatDialog,
        private adfiGraphqlService: AdfiGraphqlService,
        private adfiGrowlService: AdfiGrowlService,
        private graphqlService: AdfiGraphqlService,
        private gaService: GoogleAnalyticsService
    ) {
    }

    /**
     * on init
     */
    ngOnInit() {
        this.navRouteService.load();
        this.menus = this.navRouteService.getNavRoutes();
        this.chCencos = this.adfiService.validPermissionAllRoles(this.module, Permission.CHANGE_CENCOS);
        this.chVigecia = this.adfiService.validPermission(this.module, Permission.CHANGE_VIGENCIA);
        this.chRole = this.adfiService.validPermissionAllRoles(this.module, Permission.CHANGE_ROLE, PeriodState.NO_STATUS);
        window.addEventListener('storage', (ev) => {
            if (ev.key === AdfiUtil.encrypt('adfi_reload')) {
                window.location.reload();
            }
        });
    }

    public get breadcrumbList() {
        return this.navRouteService.breadcrumbList;
    }

    /**
     * Logouts nav component
     */
    public logout() {
        return this.adfiService.logout();
    }

    /**
     * Gets active page
     */
    public get pageBack() {
        return this.navRouteService.pageBack;
    }

    /**
     * Changes cencos
     */
    public changeCencos() {

        const user = this.adfiService.user;

        const data = {entityName: 'PrCentroCosto', module: '', action: ENUM_ACTIONS.SELECT};
        const c = this.matDialog.open(SelectItemComponent, {
                data,
                height: '80vh',
                width: '70%'
            }
        );
        const select = (row) => {
            if (row) {
                const message = `Se actualizara el centro de costo para el usuario: ${user.username}, <strong>Centro costo</strong>: ${row._id}, ${row.deDescripcion}. <br> ¿ Desea Continuar ?`;
                this.update(user, {centroCosto: row.id}, 'Cambiar de Centro de Costo', message);
                this.gaService.event('Cambio Centro de Costo', 'ADFI', row._id
                    , this.adfiService.user.id);
            }
            c.close();
            subs.unsubscribe();
        };
        const subs = c.componentInstance.dialogRef.afterClosed().subscribe(select);
    }

    /**
     * Updates employees component
     */
    private update(user: any, dataUpdate: any, title, message) {

        const data: ConfirmDialogData = {
            title,
            content: message,
            action: ENUM_ACTIONS.SELECT
        };
        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data
        });
        const callSave = (confirm) => {
            subs.unsubscribe();
            if (!confirm) {
                return;
            }

            const id = user.id === this.adfiService.user.id ? `/api/employees/${user.id}` : user.id;
            this.loading.show();

            const saveOk = (response) => {
                this.loading.hide();
                if (response) {
                    this.adfiService.user.centroCosto = response.centroCosto;
                    this.adfiService.user.cencos = response.cencos;
                    this.adfiService.user.period = response.period;
                    this.adfiGrowlService.success(`Centro costo: ${response.centroCosto._id}, con Vigencia ${response.period}`, `Actualizado para el usuario ${user.username}`);
                    AdfiUtil.saveInStorage('adfi_reload', Math.random().toString());
                    window.location.reload();
                }
                this.navRouteService.navigate('/');
            };

            const saveError = (error) => {
                this.loading.show();
                this.adfiGrowlService.errorMessage(error);
            };
            this.graphqlService.updateEntity('Employee',
                {id, ...dataUpdate}, 'centroCosto{ _id id deDescripcion} cencos period', { next: saveOk, error: saveError } );
        };

        const subs = dialogRef.afterClosed().subscribe(callSave);
    }

    changePeriod($event: MatSelectChange) {
        const user = this.adfiService.user;
        const message = `Se actualizara la vigencia actual para el usuario: ${user.username}, <strong>Vigencia</strong>: ${$event.value}. <br> ¿ Desea Continuar ?`;
        this.update(user, {period: $event.value}, 'Cambiar de Vigencia', message);
        this.gaService.event('Cambio de Vigencia', 'ADFI', $event.value
            , this.adfiService.user.id);
    }

    changeRole($event: MatSelectChange) {
        const user = this.adfiService.user;
        const message = `Se actualizara el modo de visiaulización para el usuario: ${user.username}, <br> ¿ Desea Continuar ?`;
        this.update(user, {roleView: `/api/roles/${$event.value}`}, 'Cambiar de Vista', message);
        this.gaService.event('Cambio de Rol', 'ADFI', $event.value
            , this.adfiService.user.id);
    }

    isRoleValidFor(pathType: 'statistics' | 'alternative', roleId: number | undefined): boolean {
        if (pathType === 'statistics') {
          return this.validRolesForStatistics.includes(roleId || 0);
        } else if (pathType === 'alternative') {
          return this.validRolesForAlternativePath.includes(roleId || 0);
        }
        return false;
      }
}
