import {Component, Input, OnInit} from '@angular/core';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {NAME_ENTITY, NAME_MONTHS} from '../../../config/constants';
import moment from 'moment';
import { AdfiService } from '@services/adfi-service';


interface CalendarDate {
  date: moment.Moment;
  events: CalendarEvent[];
}

interface CalendarEvent {
  title: string;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  constructor(private graphql: AdfiGraphqlService, private growl: AdfiGrowlService, private adfiService: AdfiService) {
  }

  /**
   * list of dates
   */
  listDates: Map< string, CalendarDate> = new Map<string, CalendarDate>();

  /**
   * State of loading dates
   */
  loadingDates = true;

  @Input()
  period: number;

  /**
   * Range days into filter
   */
  DAYS_RANGE = 20;

  /**
   * Return text for display on calendar with month and entity name
   * @param entity entity name
   * @param month month number 1 - 12
   * @param monthEnd month number 1 - 12
   * @param action action of add in text
   * @param nitEnd digit with en nit
   * @private
   * @return text to display in list of dates
   */
  private static getTextDate({entity, month, monthEnd, action, nitEnd }:
                                 {
                                   entity: string, month: number,
                                   monthEnd?: number, action?: string, nitEnd?: string
                                 }): string {
    return `Fecha limite para ${action ? action : 'el cargue'} de  ${NAME_ENTITY[entity] ? NAME_ENTITY[entity] : entity }${month ? ' del mes de ' : ''}
    ${month ? NAME_MONTHS[month - 1] : ''}${monthEnd ? ' a ' : ''}${monthEnd ? NAME_MONTHS[monthEnd - 1] : ''}${nitEnd ? ` para NITS terminados en ${nitEnd}` : ''}.`;
  }

  ngOnInit(): void {
    this.loadDates();
    this.loadDatesTributes();
  }

  /**
   * Builds the calendar with list of dates
   * @param listDates list of important dates
   * @private
   */
  private buildCalendarDates(listDates: {closingDate: moment.Moment, entity: string, month: number, nitEnd?: string}[]) {
    listDates.forEach(value => {
      value.closingDate = moment(value.closingDate);
      const key = value.closingDate.format('YYYY-MM-DD');
      const objEvent = {title: CalendarComponent.getTextDate(value)};
      
      const addCalendar = this.validateNit(value.nitEnd);
      if (this.listDates.has(key) && addCalendar) {
        this.listDates.get(key).events.push(objEvent);
      } else if(addCalendar){
        this.listDates.set(key, {
          date: value.closingDate,
          events: [objEvent]
        });
      }
    });
    this.listDates = new Map([...this.listDates.entries()].sort());
  }

  /**
   * Validate if nit cencos user ends with digit nit or in range 
   * @param nit last digit nit validate or range example 0, 0-5
   */
  private validateNit(nit?: string) {
    if(!nit) return true;
    if(String(this.adfiService.user.centroCosto.nit).endsWith(nit)) return true;
    if(nit.includes('-')){ // for 0-5
      const first = nit.substring(0, nit.indexOf('-'));
      const last = nit.substring(nit.indexOf('-')+1,nit.length);
      const nitCenter = this.adfiService.user.centroCosto.nit;      
      return Number(first) <= Number(nitCenter.substr(nitCenter.length - 1)) && Number(last) >= Number(nitCenter.substr(nitCenter.length - 1));
    }
    return false;
  }

  private loadDatesTributes() {
    const next = (data) => {
      this.loadingDates = false;
      this.buildCalendarDates(data.map(value => ({
          closingDate: value.endDate,
          entity: value.dateTribute.tribute.nameTribute,
          month: value.dateTribute.monthTribute,
          monthEnd: value.dateTribute.monthEnd,
          action: value.dateTribute.optionalText ? value.dateTribute.optionalText : 'pago',
          nitEnd: value.nitEnd
      })));
    };
    const error = (e) => {
      this.growl.errorMessage(e);
    };
    this.graphql.getEntityWithOutIds('nitTributes',
        'dateTribute{tribute{nameTribute} optionalText monthTribute monthEnd} nitEnd endDate', 20,
        `order:{endDate:"ASC"},
                endDate: {after:"${(new Date()).toUTCString()}", before:"${(new Date((new Date()).setDate((new Date()).getDate() + this.DAYS_RANGE))).toUTCString()}"}`,
        {next, error});

  }

  /**
   * Loads the list of important dates
   * @private
   */
  private loadDates() {
    const next = (data) => {
      this.loadingDates = false;
      this.buildCalendarDates(data);
    };
    const error = (e) => {
      this.growl.errorMessage(e);
    };
    this.graphql.getEntityWithOutIds('availableDates',
        'entity month closingDate appliesFiveDays', 20,
        `entity_list: ["ExtractBankByAccount", "ALL"], order:{closingDate:"ASC"},
                closingDate: {after:"${(new Date()).toUTCString()}", before:"${(new Date((new Date()).setDate((new Date()).getDate() + this.DAYS_RANGE))).toUTCString()}"}`,
        {next, error});
  }
}
